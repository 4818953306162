import React from "react";
import Link from "next/link";
import LaufidLogo from "@components/Icons/LaufidLogo/LaufidLogo";
import styles from "./HeaderLogo.module.scss";
import classNames from "classnames";

const HeaderLogo = () => (
    <Link className={styles.container} href="/" style={{}}>
        <LaufidLogo alt="Laufið logo" className={classNames(styles.laufid)} />
    </Link>
);
export default HeaderLogo;
