export default {
    //==========================================================
    // FRONT PAGE
    laufid_customers: "A part of our customers in Laufið",

    //==========================================================
    // HEADER
    register_your_company: "Register your company for a free trial!",

    //==========================================================
    // SIDE BAR
    home: "Home",
    login: "Login",
    software: "The Software",
    customers: "Customers",
    solutions: "Solutions",

    faq: "FAQ",
    try_free: "Book a trial",
    stories: "Stories",
    graph: "Comparison",
    certificates: "Certificates",
    about_us: "About Laufið",
    information_page: "Information Page",
    laufid_guide: "Laufið Guide",
    pricelist: "Price List",
    contact_us: "Contact Us",

    //==========================================================
    // Opening Hours
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",

    //==========================================================
    // Cookie Consent
    cookie_consent:
        "This website uses cookies to ensure you get the best experience on our website.",
    cookie_consent_button: "I understand",
    cookie_consent_link: "See our privacy policy here.",

    //==========================================================
    // Search
    searchPlaceholder: "What are you looking for?",
    searchButton: "Search",

    search_results: "Search Results",
    search_results_for: "Search Results for",

    nothing_found_with: "Nothing found with the search term",
    nothing_found_using: "using",

    companies: "Companies",
    individuals: "Individuals",

    load_more_companies: "Load More Companies",
    load_more_individuals: "Load More Individuals",

    no_products_found_for: "No products were found with the search term",
    no_results_for: "No results were found for the search term",

    not_finding_what_you_are_looking_for:
        "Not finding what you are looking for?",
    we_can_help: "We can help you at 1819",
    call_1819: "Call 1819",
    have_a_suggestion: "Have a suggestion?",
    here_you_can_send_us: "Here you can send us a suggestion",
    send_us_a_message: "Send us a message",
    you_know_where_to_look: "Searching...",

    search_result_list: "List",
    search_result_graph: "Comparison",
    search_result_products: "Products",

    open: "Open",
    closed: "Closed",

    //==========================================================
    // ADS
    ad: "ADVERTISEMENT",

    //==========================================================
    // SUSTAINABILITY
    sdg: "SDG",
    esg: "ESG",

    //==========================================================
    // INFO DETAILS
    info_details_sustainability: "Sustainability",
    info_details_carbon_neutrality: "Loftslagsáhrif",
    info_details_opening_hours: "Opening Hours",
    info_details_phone_number_singular: "Phone Number",
    info_details_phone_number_plural: "Phone Numbers",
    info_details_website_singular: "Website",
    info_details_website_plural: "Websites",
    info_details_email_singular: "Email",
    info_details_email_plural: "Emails",
    info_details_kennitala: "Kennitala",
    info_details_fyrirtaekjaskra: "Company Registry",
    info_details_social_media: "Social Media",
    info_details_map: "Map",
    info_details_tags: "Tags",
    info_details_brands: "Brands",
    info_details_services: "Services",
    info_details_other_listings: "Other Listings",
    info_details_employees: "Employees",
    info_details_navigate: "Navigate",
    info_details_more_info: "More Information",
    info_details_kennitala_abbr: "SSN:",

    //==========================================================
    // Breadcrumbs
    front_page: "Front Page",

    //==========================================================
    // MAP
    map_search_on_map: "Search on the Map",

    //==========================================================
    // OPENING HOURS
    opening_hours_open_until: "Open until",
    opening_hours_open_until_midnight: "Open until midnight",
    opening_hours_opens_at: "Opens at",
    opening_hours_closed: "Closed",
    opening_hours_always_open: "Always open",

    //==========================================================
    // CERTIFICATIONS
    more_details_link: "More Details",

    //==========================================================
    // GRAPH
    graph_companies: "Companies",
    graph_gray_zone: "Gray Zone",
    graph_green_zone: "Green Zone",
    graph_leaves: "leaves",
    graph_pluses: "Green Steps",

    //==========================================================
    // ABOUT US
    about_us_title: "Um okkur",
    about_us_section:
        "Hugmyndin um Laufið fæddist síðla árs 2020 og hefur vaxið og dafnað síðan. Við höfum lagt okkur fram við að skapa vöru og þjónustu ætlaða til að hafa samfélagsleg áhrif. Mikil vinna hefur verið lögð í að þróa vöruna með aðkomu fjölda fyrirtækja í rýnihópum, faglega aðstoð sérfræðinga í umhverfis- og sjálfbærnimálum svo ekki sé minnst á allt það hæfileikaríka starfsfólk sem hefur komið að þróuninni. Mikil áhersla er lögð á að hlustað sé á rödd framtíðar viðskiptavina og notenda sem og sérfræðinga við bestun á Laufinu.",

    our_history_title: "Sagan okkar",
    our_history_section:
        "Tvö rótgróin fyrirtæki, 1819 og Zenter ehf, eiga heiðurinn að Laufinu. 1819 var stofnað árið 2014 og er upplýsinga og þjónustufyrirtæki sem leitast við að aðstoða einstaklinga sem og fyrirtæki í upplýsingagjöf og auknum sýnileika. Zenter er hugbúnaðarfyrirtæki sem hefur frá árinu 2010 þróað og rekið hugbúnað fyrir fyrirtæki sem inniheldur m.a. tölvupósts-, SMS-, CRM- og sölutækifæriskerfi. Saman leggja þau grunninn að Laufinu.",

    services_companies_title: "Þjónusta við fyrirtæki og stjórnendur þeirra",
    services_companies_section1:
        "Laufið er stafrænn vettvangur þar sem stjórnendur íslenskra fyrirtækja geta sameinast og hagnýtt sér fjölbreytt verkfæri í vegferð að sjálfbærari fyrirtækjarekstri, samfélagslegri ábyrgð og í baráttunni við loftslagsvánna.",
    services_companies_section2:
        "Eftir samtal við stjórnendur lítilla og meðalstórra fyrirtækja á Íslandi var ljóst að fyrirtækjunum skortir hagnýt verkfæri til að stýra og taka þátt í aðgerðum sem stuðla að sjálfbærari fyrirtækjarekstri. Verkfærakista og hvatakerfi Laufsins leitast við að svara kalli stjórnenda fyrirtækja með því að leiða þau áfram í einföldum en mikilvægum aðgerðum sem draga úr umhverfisspori fyrirtækjanna og hjálpa til við að stuðla að ábyrgu samfélagi.",

    services_individuals_title: "Þjónusta við neytendur",
    services_individuals_section1:
        "Laufið er fyrsta græna íslenska upplýsingaveitan. Henni er ætlað að valdefla neytendur og gefa þeim kost á að taka upplýstar ákvarðanir um neyslu sína eftir því hversu vel fyrirtæki eru að standa sig í sjálfbærni og samfélagslegri ábyrgð.",
    services_individuals_section2:
        "Á laufid.is munu öll geta leitað að fyrirtækjum, vörum og þjónustu. Neytendur munu geta séð nákvæmlega hvað fyrirtæki eru að gera í umhverfis- og sjálfbærnimálum, gert samanburð á milli fyrirtækja, leitað að vottuðum vörum og tekið upplýstar ákvarðanir um hvaða vörur og þjónustu þau kjósa að versla og við hvaða fyrirtæki.",

    audlindatorg_title: "Auðlindatorg",
    audlindatorg_section:
        "Auðlindatorg er markaðstorg þar sem aðeins er verslað með notaða vöru. Auðlindatorgi er ætlað að einfalda fyrirtækjum að styðja við hugmyndir hringrásarhagkerfis og hvetja fyrirtæki til að hætta að hugsa einnota. Auðlindatorg verður bæði aðgengilegt á vefsíðu sem og í appi en þar verður hægt að selja, kaupa, leigja, lána eða gefa vörur á einfaldan hátt. Auðlindatorg verður aðgengilegt öllum.",

    umhverfismaelabord_title: "Umhverfismælaborð",
    umhverfismaelabord_section:
        "Umhverfismælaborðið er eitt af verkfærum Laufsins, og að hluta til okkar framlag til að ná markmiðum Parísarsáttmálans. Tilgangur mælaborðsins svarar einnig þeirri þörf að búa til, og halda utan um, stærstu gagnaveitu um stöðu umhverfis- og loftslagsmála á Íslandi. Aðaltilgangur umhverfismælaborðsins er að auðvelda ríkisstjórn, ráðherrum, stjórnum sveitarfélaga og öðrum lykil hagaðilum aðgang að gögnum sem sýna stöðu mála í rauntíma, þ.e.a.s. úrgangsmál, kolefnislosun og bindingu, loftslagsmál, orkumál, samgöngumál, stöðu byggingaiðnaðar og stöðu bifreiðar og annarra ökutækja.",
};
