import classNames from "classnames";
import React from "react";
import styles from "./Button.module.scss";

export const Button = ({
    size = "medium",
    href,
    children,
    onClick,
    className,
    target,
    rel,
    ...props
}) => {
    let buttonClassNames = [styles.container];
    if (size === "large") {
        buttonClassNames.push(styles.large);
    } else if (size === "medium") {
        buttonClassNames.push(styles.medium);
    } else if (size === "small") {
        buttonClassNames.push(styles.small);
    }

    if (href) {
        return (
            <a href={href} target={target} rel={rel}>
                <div
                    className={classNames(buttonClassNames, className)}
                    {...props}
                >
                    {children}
                </div>
            </a>
        );
    }
    return (
        <button
            className={classNames(buttonClassNames, className)}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
};
