export const WEBPAGE_TITLE = "Laufið";
export const API_ENDPOINT =
    process.env.LAUFID_ENDPOINT || "https://api.laufid.is/api/";
export const API_1819_ENDPOINT =
    process.env.OLD_1819_ENDPOINT || "https://old.1819.is/api/";
export const APP_KEY_LAUFID = process.env.APP_KEY_LAUFID;
export const GOOGLE_GA_MEASUREMENT_ID = process.env.GA_MEASUREMENT_ID;
export const GOOGLE_GA_TRACKING_ID = process.env.GA_TRACKING_ID;
export const THEME_COLOR = "#009448";
export const LEAF_COLORS = [
    { active: "#4FC878", inactive: "#A7EEBF", background: "#EEFFF4" },
    { active: "#FD974F", inactive: "#FFCEAC", background: "#FFF1E6" },
    { active: "#4433CD", inactive: "#E3C9FC", background: "#F6EEFF" },
];
