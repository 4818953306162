import React from "react";
import styles from "./Footer.module.scss";
import Link from "next/link";
import LaufidLogo from "@components/Icons/LaufidLogo/LaufidLogo";

const socials = [
    {
        icon: "/img/footer/linkedin.svg",
        url: "https://www.linkedin.com/company/laufid/",
    },
    {
        icon: "/img/footer/facebook.svg",
        url: "https://www.facebook.com/laufidiceland/",
    },
    {
        icon: "/img/footer/instagram.svg",
        url: "https://www.instagram.com/laufid.is/",
    },
    {
        icon: "/img/footer/spotify.svg",
        url: "https://open.spotify.com/show/32Nx41Hk4V4JYI8bnvTRYr",
    },
];

function Footer() {
    return (
        <>
            <footer className={styles.container}>
                <nav className={styles.contentContainer}>
                    <div>
                        <Link href="/">
                            <LaufidLogo width="120px" />
                        </Link>
                        <div style={{ paddingBottom: 20 }} />
                        Laugavegur 182
                        <br />
                        105 Reykjavík
                        <br />
                        <a href="mailto:laufid@laufid.is">laufid@laufid.is</a>
                    </div>
                    <FooterNavigationSection
                        title="Lausnir"
                        items={[
                            { title: "Fyrirtæki", link: "/lausnir/fyrirtaeki" },
                            {
                                title: "Opinberir aðilar",
                                link: "/lausnir/opinberir-adilar",
                            },
                        ]}
                    />

                    <FooterNavigationSection
                        title="Um okkur"
                        items={[
                            { title: "Um okkur", link: "/um-okkur" },
                            { title: "Hafðu samband", link: "/hafa-samband" },
                            { title: "Verðskrá", link: "/verdskra" },
                        ]}
                    />
                    <FooterNavigationSection
                        title="Viðskiptavinir"
                        items={[
                            {
                                title: "Viðskiptavinir",
                                link: "/vidskiptavinir",
                            },

                            {
                                title: "Persónuverndarstefna",
                                link: "/doc/personuvernd.pdf",
                                external: true,
                            },
                            {
                                title: "Viðskiptaskilmalar",
                                link: "/doc/vidskiptaskilmalar.pdf",
                                external: true,
                            },
                        ]}
                    />

                    <div className={styles.socialContainer}>
                        {socials.map((social, index) => {
                            return (
                                <a
                                    key={index}
                                    href={social.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className={styles.socialIcon}
                                        src={social.icon}
                                    />
                                </a>
                            );
                        })}
                    </div>
                </nav>
                <p className={styles.copyright}>
                    {`© ${new Date().getFullYear()} Laufin ehf.`}
                </p>
            </footer>
        </>
    );
}

function FooterNavigationSection({ title, items }) {
    return (
        <div>
            <h5>{title}</h5>
            <ul>
                {items?.map?.((item, index) => {
                    return (
                        <Link
                            key={index}
                            href={item.link}
                            target={item.external ? "_blank" : undefined}
                        >
                            <li key={item.title}>{item.title}</li>
                        </Link>
                    );
                })}
            </ul>
        </div>
    );
}

export default Footer;
