import { Button } from "@components/Button/Button";
import { useRouter } from "next/router";
import React from "react";
import styles from "./ErrorPage.module.scss";

export const ErrorPage = ({ title, subtitle, image }) => {
    const router = useRouter();
    const onClick = () => {
        router.push("/");
    };

    return (
        <div className={styles.container}>
            <h1>{title}</h1>
            <h5>{subtitle}</h5>
            <img src={image} />
            <Button onClick={onClick}>Fara á forsíðu</Button>
        </div>
    );
};
