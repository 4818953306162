import * as React from "react";
const AdminLockIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27 33"
        fill="none"
        {...props}
    >
        <mask
            id="a"
            width={27}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "luminance",
            }}
        >
            <path fill="#fff" d="M.945.3H26.07v32.4H.945V.3Z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#009448"
                d="M23.379 12.82v-2.658C23.379 4.725 18.95.3 13.51.3c-5.44 0-9.87 4.424-9.87 9.862v2.658a2.756 2.756 0 0 0-2.687 2.753V29.94a2.72 2.72 0 0 0 .21 1.056 2.694 2.694 0 0 0 .417.695 2.752 2.752 0 0 0 1.077.799 2.865 2.865 0 0 0 .518.157 2.82 2.82 0 0 0 .54.053h19.59a2.727 2.727 0 0 0 1.057-.21 2.682 2.682 0 0 0 .695-.417 2.802 2.802 0 0 0 .544-.6 2.88 2.88 0 0 0 .255-.477 2.748 2.748 0 0 0 .21-1.056V15.573c0-1.5-1.197-2.713-2.687-2.753Zm-7.61 7.95c-.013.086-.06.282-.065.297a2.287 2.287 0 0 1-1.095 1.354l.887 3.777a.522.522 0 0 1-.253.628.54.54 0 0 1-.234.062H12.07a.512.512 0 0 1-.335-.131.514.514 0 0 1-.173-.317.531.531 0 0 1 .021-.242l.827-3.777a2.284 2.284 0 0 1-1.095-1.354 2.17 2.17 0 0 1-.044-1.059 2.305 2.305 0 0 1 .34-.823 2.299 2.299 0 0 1 .63-.63 2.288 2.288 0 0 1 1.93-.286 2.382 2.382 0 0 1 .414.171 2.406 2.406 0 0 1 .372.249 2.3 2.3 0 0 1 .66.89 2.108 2.108 0 0 1 .13.429 2.347 2.347 0 0 1 .044.445c0 .107-.008.212-.023.318Zm4.24-7.957h-13v-2.65c0-3.583 2.916-6.497 6.501-6.497a6.503 6.503 0 0 1 6.499 6.496v2.651Z"
            />
        </g>
    </svg>
);
export default AdminLockIcon;
