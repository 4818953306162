import React, { useEffect } from "react";
import Head from "next/head";

import "@styles/root.scss";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

import Modal from "react-modal";
import ReactGA_UA from "react-ga";
import ReactGA_4 from "react-ga4";
import {
    GOOGLE_GA_MEASUREMENT_ID,
    GOOGLE_GA_TRACKING_ID,
    THEME_COLOR,
    WEBPAGE_TITLE,
} from "@utils/Constants";
import { GoogleTagManager } from "@next/third-parties/google";
import { Toaster } from "react-hot-toast";
import NextProgress from "next-progress";
import Bugsnag from "@utils/Bugsnag";
import CrashPage from "@components/Pages/CrashPage/CrashPage";
import LaufidCookieConsent from "@components/LaufidCookieConsent/LaufidCookieConsent";
import AvenirNext from "@fonts/AvenirNext/AvenirNextLTPro";
import { Analytics } from "@vercel/analytics/react";
import Script from "next/script";
import { useRouter } from "next/router";
import { AnimatePresence, motion, Spring } from "framer-motion";

const ErrorBoundary = Bugsnag.getPlugin("react");

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

Modal.setAppElement("#__next");

ReactGA_UA.initialize(GOOGLE_GA_TRACKING_ID);
ReactGA_4.initialize(GOOGLE_GA_MEASUREMENT_ID);

// This default export is required in a new `pages/_app.js` file.
function MyApp({ Component, pageProps }) {
    const description =
        "Laufið er græn íslensk upplýsingaveita og hagnýt verkfærakista fyrir stjórnendur fyrirtækja.";
    const router = useRouter();
    useEffect(() => {
        window.HubSpotConversations?.widget?.refresh();
    }, [router]);

    const transitionSpringPhysics = {
        duration: 0.6,
    };
    return (
        <ErrorBoundary FallbackComponent={CrashPage}>
            <noscript
                style={{
                    position: "fixed",
                    top: 70,
                    backgroundColor: "#A4DDBC",
                    color: THEME_COLOR,
                    padding: "8px 16px",
                    borderRadius: 100,
                    fontFamily: "sans-serif",
                    // center
                    left: "50%",
                    transform: "translateX(-50%)",
                    textAlign: "center",
                    zIndex: 1000,
                }}
            >
                JavaScript er ekki virkt. Til að vefurinn njóti sín, þarftu að
                leyfa JavaScript.
            </noscript>
            <div className={AvenirNext.className}>
                <Head>
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/apple-touch-icon.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/favicon-32x32.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/favicon-16x16.png"
                    />
                    <link rel="manifest" href="/site.webmanifest" />
                    <link
                        rel="mask-icon"
                        href="/safari-pinned-tab.svg"
                        color={THEME_COLOR}
                    />
                    <meta
                        name="msapplication-TileColor"
                        content={THEME_COLOR}
                    />

                    <meta
                        name="theme-color"
                        content={THEME_COLOR}
                        key="theme-color"
                    />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                    />
                    <meta
                        name="description"
                        content={description}
                        key="description"
                    />

                    {/* Outside Connections */}
                    <meta
                        name="google-site-verification"
                        content="etjAiv-i_cLYdb12CDsjC9OJ2nHNL9L7M6_7x23Esw8"
                    />

                    {/* Open Graph */}
                    <meta
                        property="og:title"
                        content={WEBPAGE_TITLE}
                        key="og:title"
                    />
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:image"
                        content="https://www.laufid.is/img/laufid-share.png"
                        key="og:image"
                    />
                    <meta
                        name="og:description"
                        content={description}
                        key="og:description"
                    />
                </Head>
                <GoogleTagManager gtmId="GTM-M8BB6MPZ" />
                <NextProgress color={THEME_COLOR} />
                <Toaster />
                <Component {...pageProps} />
                <LaufidCookieConsent />
                <Analytics />
                <Script
                    id="hs-script-loader"
                    src="//js-eu1.hs-scripts.com/26847850.js"
                    strategy={"lazyOnload"}
                />
            </div>
        </ErrorBoundary>
    );
}

export default MyApp;
